<template>
  <div>
    <section>
      <!-- <div class="row">
        <span class="tag_btn plain" @click="vaccineAll">全部</span>
      </div> -->
      <div class="row">
        <span class="tag_btn" :class="vaccineIndex===0?'primary':'plain'" @click="checkVaccine(1, '已接种',0)"
          >已接种</span
        >
        <span class="tag_btn" :class="vaccineIndex===1?'primary':'plain'" @click="checkVaccine(0, '未接种',1)"
          >未接种</span
        >
      </div>
    </section>
    <section>
      <div class="row">
        <h5>疫苗类型</h5>
      </div>
      <div class="row">
        <span
          class="tag_btn"
          :class="judge(index)?'primary':'plain'"
          v-for="item,index in list"
          @click="checkType(item,index)"
          >{{ item.name }}</span
        >
      </div>
    </section>
    <section>
      <div class="row">
        <h5>接种次数</h5>
      </div>
      <div class="row">
        <span class="tag_btn" :class="countIndex===1?'primary':'plain'" @click="checkCount(1, '一次')">一次</span>
        <span class="tag_btn" :class="countIndex===2?'primary':'plain'" @click="checkCount(2, '二次')">二次</span>
        <span class="tag_btn" :class="countIndex===3?'primary':'plain'" @click="checkCount(3, '三次')">三次</span>
        <span class="tag_btn" :class="countIndex===4?'primary':'plain'" @click="checkCount(4, '三次以上')"
          >三次以上</span
        >
      </div>
    </section>
  </div>
</template>

<script>
import { vaccineTree } from "@/api/api";
import search from "@/mixins/search"
export default {
  data() {
    return {
      types: [],
      list: [],
      vaccineIndex:'',
      typeIndex:'',
      countIndex:'',
      idx:[]
    };
  },
  created() {
    this.getData();
  },
  mixins:[search],
  methods: {
    async getData() {
      let res = await vaccineTree();
      this.list = res.data.data;
    },
    checkVaccine(type, name,index) {
      this.$store.commit("setSearch", {
        name: name,
        value: type,
        type: "isVac",
      });
      this.vaccineIndex = this.checkSearch('isVac',type)?index:''
    },
    checkCount(type, name) {
      this.$store.commit("setSearch", {
        name: name,
        value: type,
        type: "vacCount",
      });
      this.countIndex = this.checkSearch('vacCount',type)?type:''
    },
    checkType(item,index) {
      let result = this.idx.some((item) => item == index);
      if (!result) {
        this.idx.push(index);
      } else {
        this.idx = this.idx.filter((item) => item != index);
      }
      this.$store.commit("setSearch", {
        name: [item.name],
        value: [item.id],
        type: "vacTypeList",
      });
      //this.typeIndex = this.checkSearch('vacType',item.id)?index:''
    },
    vaccineAll(){
      this.$store.commit('delSearch',{type:'isVac'})
    },
    clear(type){
      switch(type){
        case 'isVac':
          this.vaccineIndex = '';
          break;
        case 'vacCount':
          this.countIndex = '';
          break;
        case 'vacTypeList':
          this.idx = [];
          break
      }
    },
    judge(index) {
      return this.idx.some((item) => item == index);
    },
  },
};
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.row::after {
  content: "";
  width: 0.7rem;
}
.row span {
  margin: 0.1rem 0;
}
section {
  padding: 0.1rem 0;
}

h5 {
  color: #595e64;
  font-size: 0.16rem;
}
</style>