<template>
  <div>
    <section>
      <!-- <div class="row"><h5>机构</h5></div> -->
      <div class="row">
        <p
          class="tag_btn plain"
          @click="choose(index, item, 'organization')"
          v-for="(item, index) in list"
          :class="judge(index) ? 'active' : ''"
          :key="item.id"
        >
          <span class="check"></span>
          {{ item.name }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import { getOrganization } from "@/api/search";
export default {
  data() {
    return {
      idx: [],
      cidx: "",
      pidx: "",
      list: [],
      countries: [],
      projects: [],
      flag: true,
    };
  },
  created() {
    this.getData();
    console.log(this.$store);
    this.$store.dispatch("getData");
  },
  methods: {
    choose(index,obj,type) {
      let result = this.idx.some((item) => item == index);
      if (!result) {
        this.idx.push(index);
      } else {
        this.idx = this.idx.filter((item) => item != index);
      }
      this.$store.dispatch('setCountries',{id:obj.id})
        this.$store.commit("setSearch", {
          name: [obj.name],
          value: [obj.name],
          type: "institution",
        });
    },
    judge(index) {
      return this.idx.some(item=>item==index)
    },
    async getData() {
      let res = await getOrganization({flag:1});
      this.list = res.data.data;
    },
    async getCountries(id) {
      let res = await getOrganization({ parentId: id });
      this.countries = res.data.data;
      this.projects = [];
    },
    async getProjects(id) {
      let res = await getOrganization({ parentId: id });
      this.projects = res.data.data;
    },
    organAll() {
      this.$store.commit("delSearch", { type: "institution" });
      this.countries = [];
      this.projects = [];
    },
    countryAll() {
      this.$store.commit("delSearch", { type: "country" });
      this.projects = [];
    },
    projectAll() {
      this.$store.commit("delSearch", { type: "project" });
      this.projects = [];
    },
    handleCheckChange(e) {
      let type = !e.parentId
        ? "organization"
        : e.parentId == null
        ? "country"
        : "project";
      this.choose(e, type);
      return;
    },
    clear(type) {
      this.$store.dispatch("getData");
      this.idx = []
    },
  },
};
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.row::after {
  content: "";
  width: 0.7rem;
}
.row p {
  margin: 0.1rem 0;
}
section {
  padding: 0.1rem 0;
}

h5 {
  color: #595e64;
  font-size: 0.16rem;
}
</style>