<template>
  <div>
    <div class="tab">
      <span
        class="radio"
        :class="tab == 0 ? 'active' : ''"
        @click="tabChange(0)"
        >境内</span
      >
      <span
        class="radio"
        :class="tab == 1 ? 'active' : ''"
        @click="tabChange(1)"
        >境外</span
      >
    </div>
    <el-cascader
      filterable
      size="small"
      :options="options_"
      v-model="selectedOptions"
      @change="changePosition"
      placeholder="区域"
      :props="{ checkStrictly: true }"
      v-show="tab == 0"
    ></el-cascader>
    <el-select v-model="value" placeholder="请选择国家" v-show="tab == 1" size="small" @change="select">
      <el-option
        v-for="item in countries"
        :key="item.id"
        :label="item.label"
        :value="item.label"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
  TextToCode,
} from "element-china-area-data";
import { countryList } from "@/api/api";
export default {
  data() {
    return {
      options_: provinceAndCityData,
      selectedOptions: [],
      query: {},
      tab: 0,
      value: "",
      countries: [],
    };
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    tabChange(t) {
      this.tab = t;
      if(t==1){
        this.$store.commit('setSearch',{name:'境外',value:t,type:'inChina'})
      }
    },
    changePosition(e) {
      this.query.cityName = null;
      this.query.provinceName = null;
      console.log(e)
      regionData.forEach((parent) => {
        if (parent.value == e[0]) {
          this.query.provinceName = parent.label;
          parent.children.forEach((item) => {
            if (item.value == e[1]) {
              if (item.label == "市辖区") {
                this.query.cityName = this.query.provinceName;
              } else {
                this.query.cityName = item.label
              }
            }
          });
        }
      });
      console.log(this.query)
      this.$store.commit("setSearch", {
        name: this.query.provinceName,
        value: this.query.provinceName,
        type: "province",
      });
      this.$store.commit("setSearch", {
        name: this.query.cityName,
        value: this.query.cityName,
        type: "city",
      });
    },
    async getCountries() {
      let res = await countryList();
      this.countries = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });
    },
    select(e){
      this.$store.commit('setSearch',{name:e,value:e,type:'abroadName'})
    },
    clear(type){
      switch(type){
        case 'province':
          this.selectedOptions = ''
          break;
        case 'abroadName':
          this.value = ''
          break;
      }
    }
  },
};
</script>

<style scoped>
.tab {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.2rem;
}
.radio {
  font-size: 0.12rem;
  flex: 1;
  text-align: center;
  background: #ededed;
  height: 0.28rem;
  line-height: 0.28rem;
  border-radius: 0.14rem;
  position: relative;
  z-index: 8;
}
.radio:first-child {
  transform: translateX(30%);
  padding-right: 0.06rem;
}
.radio:last-child {
  padding-left: 0.06rem;
}
.radio.active {
  background: #2f7dcd;
  font-weight: bold;
  color: #fff;
  z-index: 10;
}
</style>