
export default {
    methods: {
        checkSearch(type,value){
            let search = this.$store.state.search
            let idx = search.findIndex(item=>item.type===type)
            if(idx>=0){
                return  search[idx].value === value?true:false
            }else{
                return false
            }
        }
    },
  };
  