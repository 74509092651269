<template>
  <div>
    <section>
      <div class="row">
        <span class="tag_btn" :class="transIndex===index?'primary':'plain'" v-for="item,index in list" @click="checkTrans(item,index)">{{item.name}}</span>
      </div>
      <div class="row">
          <input type="text" placeholder="请输入班次" v-model="number" @blur="checkNum">
      </div>
    </section>
  </div>
</template>

<script>
import {TripList} from "@/api/api"
import search from "@/mixins/search"
export default {
    data(){
        return{
            list:[],
            number:'',
            transIndex:''
        }
    },
    mixins:[search],
    created(){
        this.getList()
    },
    methods:{
        getList(){
            this.list = [{
              label:'火车',
              value:1
            },{
              label:'飞机',
              value:2
            },{
              label:'其他',
              value:3
            }]
        },
        checkTrans(item,index){
          this.$store.commit('setSearch',{name:item.name,value:item.id,type:'tripWayId'})
          this.transIndex = this.checkSearch('tripWayId',item.id)?index:''

        },
        checkNum(){
          this.$store.commit('setSearch',{name:this.number,value:this.number,type:'tripNo'})
        },
        clear(type){
          switch(type){
            case 'tripWayId':
              this.transIndex = '';
              break;
            case 'tripNo':
              this.number = '';
              break
          }
        }
    }
};
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.row::after{
    content: '';
    width: 0.7rem;
}
.row span {
  margin: 0.1rem 0;
}
section{
    padding: 0.1rem 0;
}
.row input{
    border: 1px solid #C3BCBC;
    border-radius: 5px;
    outline: none;
    line-height: 0.3rem;
    padding: 0 0.1rem;
    width: 100%;
    margin: 0.1rem 0;
}

</style>