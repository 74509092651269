<template>
  <section class="container">
    <div class="menu">
      <ul>
        <li
          :class="tab == 1 ? 'active' : ''"
          @click="changeTab(1)"
        >
          机构
        </li>
        <li :class="tab == 2 ? 'active' : ''" @click="changeTab(2)">国别</li>
        <li :class="tab == 3 ? 'active' : ''" @click="changeTab(3)">项目</li>
        <li :class="tab == 4 ? 'active' : ''" @click="changeTab(4)">
          隔离/检测
        </li>
        <li :class="tab == 5 ? 'active' : ''" @click="changeTab(5)">
          核酸检测
        </li>
        <li :class="tab == 6 ? 'active' : ''" @click="changeTab(6)">
          接种疫苗
        </li>
        <li :class="tab == 7 ? 'active' : ''" @click="changeTab(7)">
          行程查询
        </li>
        <!-- <li :class="tab == 8 ? 'active' : ''" @click="changeTab(8)">
          人员位置
        </li> -->
        <!-- <li :class="tab == 9 ? 'active' : ''" @click="changeTab(9)">
          交通工具
        </li>
        <li :class="tab == 10 ? 'active' : ''" @click="changeTab(10)">时间</li> -->
      </ul>
    </div>
    <div class="right">
      <keep-alive>
        <component :is="componentId" :ref="componentId"></component>
      </keep-alive>
    </div>
    <div class="searchBox">
      <span
        class="search-tag"
        v-for="item in search"
        v-if="(typeof item.name === 'string' || (item.name&&item.name.length > 0)) && item.type!='inChina'"
        >{{ typeof item.name === "object" ? item.name.join(",") : item.name }}
        <i class="el-icon-close" @click="delSearch(item)"></i>
      </span>
    </div>
    <div class="search" @click="$router.replace('/person/list')">查询</div>
  </section>
</template>

<script>
import person_status from "./personStatus";
import risk_area from "./riskArea";
import transport from "./transport";
import nuclear from "./nuclear";
import vaccine from "./vaccine";
import position from "./position";
import picktime from "./time";
import organization from "./organization";
import country from "./country";
import project from "./project";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tab: 1,
    };
  },
  computed: {
    componentId() {
      let id = "";
      switch (this.tab) {
        case 1:
          id = "organization";
          break;
        case 2:
          id = "country";
          break;
        case 3:
          id = "project";
          break;
        case 4:
          id = "person_status";
          break;
        case 5:
          id = "nuclear";
          break;
        case 6:
          id = "vaccine";
          break;
        case 7:
          id = "risk_area";
          break;
        case 8:
          id = "position";
          break;
        case 9:
          id = "transport";
          break;
        case 10:
          id = "picktime";
          break;
        default:
          id = "organization";
          break;
      }
      return id;
    },
    ...mapGetters(["search"]),
  },
  methods: {
    changeTab(type) {
      this.tab = type;
    },
    delSearch(item) {
      let types = ["institution", "country", "project","inLevelList","tripWayIdList","vacTypeList","isolateTypeList"];
      if (
        item.type == "userProvince" ||
        item.type == "userCity" ||
        item.type == "userDistrict"
      ) {
        this.$refs[this.componentId].clear("userProvince");
        this.$refs[this.componentId].clear("userCity");
        this.$refs[this.componentId].clear("userDistrict");
        this.$store.commit("setSearch", {name:1, type: "userProvince" });
        this.$store.commit("setSearch", {name:1, type: "userCity" });
        this.$store.commit("setSearch", {name:1, type: "userDistrict" });
      } else {
        this.$refs[this.componentId].clear(item.type);
        if (types.indexOf(item.type) > -1) {
          this.$store.commit("delSearch", item);
        } else {
          this.$store.commit("setSearch", item);
        }
      }
    },
  },
  components: {
    person_status,
    risk_area,
    transport,
    nuclear,
    vaccine,
    position,
    picktime,
    organization,
    country,
    project,
  },
};
</script>

<style scoped>
.container {
  background: #fafafa;
  min-height: 100%;
  padding-left: 1rem;
}
.container .menu {
  background: #fff;
  position: fixed;
  width: 1rem;
  height: 100vh;
  top: 0;
  left: 0;
}
.menu ul li {
  color: #595e64;
  font-size: 0.16rem;
  line-height: 0.25rem;
  margin: 0.34rem 0;
  text-align: center;
}
.menu ul li.active {
  color: #407ac2;
  font-weight: bold;
}
.right {
  height: 100%;
  padding: 0.2rem;
}
.search {
  position: fixed;
  width: 3.2rem;
  background: #2f7dcd;
  line-height: 0.36rem;
  border-radius: 0.18rem;
  color: #fff;
  text-align: center;
  left: 50%;
  bottom: 0.3rem;
  transform: translateX(-50%);
  font-size: 0.14rem;
  z-index: 9999;
}
.searchBox {
  position: fixed;
  bottom: 0.8rem;
  width: 100%;
  left: 0;
  padding: 0 0.1rem;
  z-index: 999;
  display: flex;
  flex-wrap: wrap;
}
.search-tag {
  min-width: 0.7rem;
  line-height: 0.3rem;
  color: #fff;
  display: inline-block;
  border-radius: 5px;
  text-align: center;
  line-height: 0.3rem;
  font-size: 0.14rem;
  padding: 0 7px;
  background: #dae1eb;
  color: #2f7dcd;
  margin: 5px;
}
</style>