<template>
  <div>
    <section>
      <div class="row">
        <h5>开始时间</h5>
      </div>
      <div class="row">
        <el-date-picker
          v-model="start"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          @change="handleStart"
          :clearable="false"
          size="small"
        >
        </el-date-picker>
      </div>
    </section>
    <section>
      <div class="row">
        <h5>结束时间</h5>
      </div>
      <div class="row">
        <el-date-picker
          v-model="end"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          @change="handleEnd"
          :clearable="false"
          size="small"
        >
        </el-date-picker>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      start: "",
      end: "",
    };
  },
  methods: {
    handleStart() {
      this.$store.commit("setSearch", {
        name: this.start,
        value: this.start + " 00:00:00",
        type: "startTime",
      });
      if (!this.end) {
        this.end = this.start;
        this.$store.commit("setSearch", {
          name: this.end,
          value: this.end + " 23:59:59",
          type: "endTime",
        });
      }
    },
    handleEnd() {
      if (!this.start) {
        this.start = this.end;
        this.$store.commit("setSearch", {
          name: this.start,
          value: this.start + " 00:00:00",
          type: "startTime",
        });
      }
      this.$store.commit("setSearch", {
        name: this.end,
        value: this.end + " 23:59:59",
        type: "endTime",
      });
    },
    clear(type){
      switch(type){
        case 'startTime':
          this.start = ''
          break;
        case 'endTime':
          this.end = ''
          break;
      }
    }
  },
};
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0.1rem 0;
}
.row::after {
  content: "";
  width: 0.7rem;
}
.row span {
  margin: 0.1rem 0;
}
section {
  padding: 0.1rem 0;
}
h5 {
  color: #595e64;
  font-size: 0.16rem;
}
</style>