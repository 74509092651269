<template>
  <div>
    <section>
      <div class="row">
        <h5>隔离状态</h5>
      </div>
      <div class="row">
        <span
          class="tag_btn"
          :class="judge(index) ? 'primary' : 'plain'"
          v-for="(item, index) in types"
          @click="status(item, index)"
          :key="index"
          >{{ item.name }}</span
        >
      </div>
    </section>
    <section>
      <div class="row">
        <h5>密切接触</h5>
      </div>
      <div class="row">
        <span
          class="tag_btn"
          :class="closeFlag ? 'info' : 'infoplain'"
          @click="isClose({ name: '密切接触', value: 1 }, 1)"
          >密切接触</span
        >
        <span
          class="tag_btn"
          :class="subFlag ? 'info' : 'infoplain'"
          @click="isSubClose({ name: '次密切接触', value: 1 }, 1)"
          >次密切接触</span
        >
      </div>
    </section>
    <section>
      <div class="row">
        <h5>开始日期</h5>
      </div>
      <div class="row">
        <el-date-picker
          v-model="start"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          @change="handleStart"
          :clearable="false"
          size="small"
        >
        </el-date-picker>
      </div>
    </section>
    <section>
      <div class="row">
        <h5>结束日期</h5>
      </div>
      <div class="row">
        <el-date-picker
          v-model="end"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          @change="handleEnd"
          :clearable="false"
          size="small"
        >
        </el-date-picker>
      </div>
    </section>
  </div>
</template>

<script>
import { IsolateType } from "@/api/api";
import search from "@/mixins/search";
export default {
  data() {
    return {
      types: [],
      statusIndex: "",
      closeFlag: false,
      subFlag: false,
      start: "",
      end: "",
      idx: [],
    };
  },
  mounted() {
    this.getTypes();
  },
  mixins: [search],
  methods: {
    async getTypes() {
      let res = await IsolateType();
      this.types = res.data.data;
    },
    status(item, index) {
      let result = this.idx.some((item) => item == index);
      if (!result) {
        this.idx.push(index);
      } else {
        this.idx = this.idx.filter((item) => item != index);
      }
      this.$store.commit("setSearch", {
        name: [item.name],
        value: [item.id],
        type: "isolateTypeList",
      });
      //this.statusIndex = this.checkSearch("isolateType", item.id) ? index : "";
    },
    isClose(item, index) {
      this.$store.commit("setSearch", {
        name: "密切接触",
        value: 1,
        type: "isClose",
      });
      this.closeFlag = this.checkSearch("isClose", item.value);
    },
    isSubClose(item) {
      this.$store.commit("setSearch", {
        name: "次密切接触",
        value: 1,
        type: "isSubClose",
      });
      this.subFlag = this.checkSearch("isSubClose", item.value);
    },
    statusAll() {
      this.$store.commit("delSearch", { type: "isolateTypeList" });
    },
    closeAll() {
      this.$store.commit("delSearch", { type: "isClose" });
      this.$store.commit("delSearch", { type: "isSubClose" });
    },
    clear(type) {
      switch (type) {
        case "isolateTypeList":
          this.idx = [];
          break;
        case "isClose":
          this.closeFlag = false;
          break;
        case "isSubClose":
          this.subFlag = false;
          break;
        case "isolateStartTime":
          this.start = "";
          break;
        case "isolateEndTime":
          this.end = "";
          break;
      }
    },
    handleStart() {
      this.$store.commit("setSearch", {
        name: "隔离开始：" + this.start,
        value: this.start + " 00:00:00",
        type: "isolateStartTime",
      });
      if (!this.end) {
        this.end = this.start;
        this.$store.commit("setSearch", {
          name: "隔离结束：" + this.end,
          value: this.end + " 23:59:59",
          type: "isolateEndTime",
        });
      }
    },
    handleEnd() {
      if (!this.start) {
        this.start = this.end;
        this.$store.commit("setSearch", {
          name: "隔离开始：" + this.start,
          value: this.start + " 00:00:00",
          type: "isolateStartTime",
        });
      }
      this.$store.commit("setSearch", {
        name: "隔离结束：" + this.end,
        value: this.end + " 23:59:59",
        type: "isolateEndTime",
      });
    },
    judge(index) {
      return this.idx.some((item) => item == index);
    },
  },
};
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.row::after {
  content: "";
  width: 0.7rem;
}
.row span {
  margin: 0.1rem 0;
}
section {
  padding: 0.1rem 0;
}
section:first-child {
  border-bottom: 1px solid #707070;
}
h5 {
  color: #595e64;
  font-size: 0.16rem;
  margin-bottom: 0.1rem;
}
</style>