<template>
  <div>
    <section>
      <!-- <div class="row">
        <p class="tag_btn primary">全部</p>
      </div> -->
      <div class="row">
        <p
          class="tag_btn plain"
          :class="judge(index) ? 'active' : ''"
          @click="choose(index, item)"
          v-for="(item, index) in projects"
        >
          <span class="check"></span>{{ item.name }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      idx: [],
      list: [],
    };
  },
  computed: {
    ...mapGetters(["projects"]),
  },
  methods: {
    choose(index, obj) {
      let result = this.idx.some((item) => item == index);
      if (!result) {
        this.idx.push(index);
      } else {
        this.idx = this.idx.filter((item) => item != index);
      }
      this.$store.commit("setSearch", {
        name: [obj.name],
        value: [obj.name],
        type: "project",
      });
    },
    judge(index) {
      return this.idx.some((item) => item == index);
    },
    clear(type) {
      this.$store.dispatch("getData");
      this.idx = [];
    },
  },
};
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.row::after {
  content: "";
  width: 0.7rem;
}
.row p {
  margin: 0.1rem 0;
}
section {
  padding: 0.1rem 0;
}

h5 {
  color: #595e64;
  font-size: 0.16rem;
}
</style>