<template>
  <div>
    <section>
      <!-- <div class="row">
        <span class="tag_btn plain" @click="testAll">全部</span>
      </div> -->
      <div class="row">
        <span class="tag_btn" :class="testIndex===0?'primary':'plain'" @click="test(1,0)">已检测</span>
        <span class="tag_btn" :class="testIndex===1?'primary':'plain'" @click="test(0,1)">未检测</span>
      </div>
    </section>
    <section>
      <div class="row">
        <h5>检测结果</h5>
      </div>
      <!-- <div class="row">
        <span class="tag_btn plain" @click="resultAll">全部</span>
      </div> -->
      <div class="row">
        <span class="tag_btn " :class="resultIndex===0?'primary':'plain'" @click="checkResult(0,0)">阴性</span>
        <span class="tag_btn " :class="resultIndex===1?'info':'infoplain'" @click="checkResult(1,1)">阳性</span>
      </div>
    </section>
    <section>
      <div class="row">
        <h5>开始日期</h5>
      </div>
      <div class="row">
        <el-date-picker
          v-model="start"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          @change="handleStart"
          :clearable="false"
          size="small"
        >
        </el-date-picker>
      </div>
    </section>
    <section>
      <div class="row">
        <h5>结束日期</h5>
      </div>
      <div class="row">
        <el-date-picker
          v-model="end"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          @change="handleEnd"
          :clearable="false"
          size="small"
        >
        </el-date-picker>
      </div>
    </section>
  </div>
</template>

<script>
import search from "@/mixins/search"
export default {
  data(){
    return {
      testIndex:'',
      resultIndex:'',
      start:'',
      end:''
    }
  },
  methods:{
    test(type,index){
      this.$store.commit('setSearch',{name:type==1?'已检测':'未检测',value:type||0,type:'isCheck'})
      this.testIndex = this.checkSearch('isCheck',type)?index:''
    },
    checkResult(type,index){
      this.$store.commit('setSearch',{name:type==1?'阳性':'阴性',value:type||0,type:'result'})
      this.resultIndex = this.checkSearch('result',type)?index:''
    },
    testAll(){
      this.$store.commit('delSearch',{type:'isCheck'})
    },
    resultAll(){
      this.$store.commit('delSearch',{type:'result'})
    },
    clear(type){
      switch(type){
        case 'isCheck':
          this.testIndex = '';
          break;
        case 'result':
          this.resultIndex = '';
          break;
      }
    },
    handleStart() {
      this.$store.commit("setSearch", {
        name: "检测开始：" + this.start,
        value: this.start + " 00:00:00",
        type: "nucleicStartTime",
      });
      if (!this.end) {
        this.end = this.start;
        this.$store.commit("setSearch", {
          name: "检测结束：" + this.end,
          value: this.end + " 23:59:59",
          type: "nucleicEndTime",
        });
      }
    },
    handleEnd() {
      if (!this.start) {
        this.start = this.end;
        this.$store.commit("setSearch", {
          name: "检测开始：" + this.start,
          value: this.start + " 00:00:00",
          type: "nucleicStartTime",
        });
      }
      this.$store.commit("setSearch", {
        name: "检测结束：" + this.end,
        value: this.end + " 23:59:59",
        type: "nucleicEndTime",
      });
    },
  },
  mixins:[search]
};
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.row::after {
  content: "";
  width: 0.7rem;
}
.row span {
  margin: 0.1rem 0;
}
section {
  padding: 0.1rem 0;
}

h5{
    color: #595E64;
    font-size: 0.16rem;
    margin-bottom: 0.1rem;
}
</style>