<template>
  <div>
    <section>
      <div class="row">
        <h5>风险等级</h5>
      </div>
      <div class="row">
        <span
          class="tag_btn"
          :class="judge(0) ? 'primary' : 'plain'"
          @click="checkLevel(3, '高风险', 0)"
          >高风险</span
        >
        <span
          class="tag_btn"
          :class="judge(1) ? 'primary' : 'plain'"
          @click="checkLevel(2, '中风险', 1)"
          >中风险</span
        >
        <span
          class="tag_btn"
          :class="judge(2) ? 'primary' : 'plain'"
          @click="checkLevel(1, '低风险', 2)"
          >低风险</span
        >
      </div>
    </section>
    <section>
      <div class="row">
        <h5>人员位置</h5>
      </div>
      <div class="tab">
        <span
          class="radio"
          :class="tab == 0 ? 'active' : ''"
          @click="tabChange(0)"
          >中国</span
        >
        <span
          class="radio"
          :class="tab == 1 ? 'active' : ''"
          @click="tabChange(1)"
          >海外</span
        >
      </div>
      <el-cascader
        filterable
        size="small"
        :options="options_"
        v-model="selectedOptions"
        @change="changePosition"
        placeholder="区域"
        :props="{ checkStrictly: true, value: 'label' }"
        style="width: 100%"
        v-show="tab == 0"
      ></el-cascader>
      <el-select
        v-model="value"
        placeholder="请选择国家"
        v-show="tab == 1"
        size="small"
        @change="select"
        style="width: 100%"
      >
        <el-option
          v-for="item in countries"
          :key="item.id"
          :label="item.label"
          :value="item.label"
        >
        </el-option>
      </el-select>
    </section>

    <section>
      <div class="row">
        <h5>交通工具</h5>
      </div>
      <div class="row">
        <span
          class="tag_btn"
          :class="tjudge(index) ? 'primary' : 'plain'"
          v-for="(item, index) in list"
          @click="checkTrans(item, index)"
          :key="item.id"
          >{{ item.name }}</span
        >
      </div>
      <div class="row">
        <el-input
          type="text"
          placeholder="请输入班次"
          v-model="number"
          @blur="checkNum"
          size="small"
        />
      </div>
    </section>
    <section>
      <div class="row">
        <h5>开始日期</h5>
      </div>
      <div class="row">
        <el-date-picker
          v-model="start"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          @change="handleStart"
          :clearable="false"
          size="small"
        >
        </el-date-picker>
      </div>
    </section>
    <section>
      <div class="row">
        <h5>结束日期</h5>
      </div>
      <div class="row">
        <el-date-picker
          v-model="end"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          @change="handleEnd"
          :clearable="false"
          size="small"
        >
        </el-date-picker>
      </div>
    </section>
    <!-- <section>
      <div class="row">
        <h5>常驻地</h5>
      </div>
      <div class="row">
        <el-select
          v-model="acountry"
          placeholder="请选择国家"
          size="small"
          @change="alwaysCountry"
          style="width: 100%"
        >
          <el-option
            v-for="item in countries"
            :key="item.id"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
      <div class="row" style="margin-top: 20px">
        <el-cascader
          filterable
          size="small"
          :options="options_"
          v-model="selectValue"
          @change="changeAreaPosition"
          placeholder="省市区"
          :props="{ checkStrictly: true, value: 'label' }"
          style="width: 100%"
          v-if="showAreaFlag"
        ></el-cascader>
      </div>
    </section> -->
  </div>
</template>

<script>
import search from "@/mixins/search";
import { TripList } from "@/api/api";
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
  TextToCode,
} from "element-china-area-data";
import { countryList } from "@/api/api";
import { mapGetters } from "vuex";
export default {
  mixins: [search],
  data() {
    return {
      levelIndex: "",
      list: [],
      number: "",
      transIndex: "",
      options_: provinceAndCityData,
      selectedOptions: [],
      query: {},
      tab: 0,
      value: "",
      countries: [],
      start: "",
      end: "",
      acountry: "",
      selectValue: [],
      idx: [],
      tidx: [],
    };
  },
  created() {
    this.getList();
    this.getCountries();
    this.$store.commit("setSearch", {
      name: "中国",
      value: t,
      type: "inChina",
    });
  },
  computed: {
    showAreaFlag() {
      return this.acountry == "中国";
    },
    ...mapGetters(["search"]),
  },
  methods: {
    checkLevel(type, name, index) {
      let result = this.idx.some((item) => item == index);
      if (!result) {
        this.idx.push(index);
      } else {
        this.idx = this.idx.filter((item) => item != index);
      }
      this.$store.commit("setSearch", {
        name: [name],
        value: [type],
        type: "inLevelList",
      });
      //this.levelIndex = this.checkSearch("inLevel", type) ? type : "";
    },
    areaAll() {
      this.$store.commit("delSearch", { type: "inLevelList" });
    },
    async getList() {
      this.list = [
        {
          name: "火车",
          id: 1,
        },
        {
          name: "飞机",
          id: 2,
        },
        {
          name: "其他",
          id: 3,
        },
      ];
    },
    checkTrans(item, index) {
      let result = this.tidx.some((item) => item == index);
      if (!result) {
        this.tidx.push(index);
      } else {
        this.tidx = this.tidx.filter((item) => item != index);
      }
      this.$store.commit("setSearch", {
        name: [item.name],
        value: [item.id],
        type: "tripWayIdList",
      });
      //this.transIndex = this.checkSearch("tripWayId", item.id) ? index : "";
    },
    checkNum() {
      this.number != "" &&
        this.$store.commit("setSearch", {
          name: this.number,
          value: this.number,
          type: "tripNo",
        });
    },
    judge(index) {
      return this.idx.some((item) => item == index);
    },
    tjudge(index) {
      return this.tidx.some((item) => item == index);
    },
    clear(type) {
      switch (type) {
        case "inLevelList":
          this.idx = [];
          break;
        case "tripWayIdList":
          this.tidx = [];
          break;
        case "tripNo":
          this.number = "";
          break;
        case "province":
          this.selectedOptions = "";
          break;
        case "abroadName":
          this.value = "";
          break;
        case "tripStartTime":
          this.start = "";
          break;
        case "tripEndTime":
          this.end = "";
          break;
        case "userAlwaysCountry":
          this.acountry = "";
          break;
        case "userProvince":
          this.selectValue = [];
          break;
        case "userCity":
          this.selectValue = [];
          break;
        case "userDistrict":
          this.selectValue = [];
          break;
      }
    },
    tabChange(t) {
      this.tab = t;
      if (t == 1) {
        this.$store.commit("setSearch", {
          name: "海外",
          value: t,
          type: "inChina",
        });
      } else {
        this.$store.commit("setSearch", {
          name: "中国",
          value: t,
          type: "inChina",
        });
      }
    },
    changePosition(e) {
      if (!e) {
        return;
      }
      console.log(e);
      this.query.cityName = e[1];
      this.query.provinceName = e[0];
      // regionData.forEach((parent) => {
      //   console.log(parent)
      //   if (parent.value == e[0]) {
      //     this.query.provinceName = parent.label;
      //     parent.children.forEach((item) => {
      //       if (item.value == e[1]) {
      //         this.query.cityName = item.label;
      //       }
      //     });
      //   }
      // });
      // console.log(this.query)
      if (e[1]) {
        this.$store.commit("setSearch", {
          name: this.query.cityName,
          value: this.query.cityName,
          type: "city",
        });
      } else {
        this.$store.commit("setSearch", {
          name: this.query.provinceName,
          value: this.query.provinceName,
          type: "province",
        });
      }
    },
    changeAreaPosition(e) {
      if (
        e[0] &&
        !this.search.some((item) => {
          return item.type == "userProvince";
        })
      ) {
        this.$store.commit("setSearch", {
          name: e[0],
          value: e[0],
          type: "userProvince",
        });
      }
      if (
        e[1] &&
        !this.search.some((item) => {
          return item.type == "userCity";
        })
      ) {
        this.$store.commit("setSearch", {
          name: e[1],
          value: e[1],
          type: "userCity",
        });
      }
      if (
        e[2] &&
        !this.search.some((item) => {
          return item.type == "userDistrict";
        })
      ) {
        this.$store.commit("setSearch", {
          name: e[2],
          value: e[2],
          type: "userDistrict",
        });
      }
    },
    async getCountries() {
      let res = await countryList();
      this.countries = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });
    },
    select(e) {
      this.$store.commit("setSearch", {
        name: e,
        value: e,
        type: "abroadName",
      });
    },
    alwaysCountry(e) {
      this.$store.commit("setSearch", {
        name: e,
        value: e,
        type: "userAlwaysCountry",
      });
    },
    handleStart() {
      this.$store.commit("setSearch", {
        name: "行程开始：" + this.start,
        value: this.start + " 00:00:00",
        type: "tripStartTime",
      });
      if (!this.end) {
        this.end = this.start;
        this.$store.commit("setSearch", {
          name: "行程结束：" + this.end,
          value: this.end + " 23:59:59",
          type: "tripEndTime",
        });
      }
    },
    handleEnd() {
      if (!this.start) {
        this.start = this.end;
        this.$store.commit("setSearch", {
          name: "行程开始：" + this.start,
          value: this.start + " 00:00:00",
          type: "tripStartTime",
        });
      }
      this.$store.commit("setSearch", {
        name: "行程结束：" + this.end,
        value: this.end + " 23:59:59",
        type: "tripEndTime",
      });
    },
  },
};
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.row::after {
  content: "";
  width: 0.7rem;
}
.row span {
  margin: 0.1rem 0;
}
section {
  padding: 0.1rem 0;
}
h5 {
  color: #595e64;
  font-size: 0.16rem;
  margin-bottom: 0.1rem;
}
.row input {
  border: 1px solid #c3bcbc;
  border-radius: 5px;
  outline: none;
  line-height: 0.3rem;
  padding: 0 0.1rem;
  width: 100%;
  margin: 0.1rem 0;
}
.tab {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.2rem;
  margin-left: -0.4rem;
}
.radio {
  font-size: 0.12rem;
  flex: 1;
  text-align: center;
  background: #ededed;
  height: 0.28rem;
  line-height: 0.28rem;
  border-radius: 0.14rem;
  position: relative;
  z-index: 8;
}
.radio:first-child {
  transform: translateX(30%);
  padding-right: 0.06rem;
}
.radio:last-child {
  padding-left: 0.06rem;
}
.radio.active {
  background: #2f7dcd;
  font-weight: bold;
  color: #fff;
  z-index: 10;
}
</style>